import { Header, Subheader, useStyles } from '@archipro-design/aria';
import * as S from './SomethingWrong.style';
import { useRootData } from '@modules/root/hook/use-root-data';

const SomethingWrong = () => {
    const { css } = useStyles();
    const data = useRootData();
    const requestId = data?.requestId;

    return (
        <div className={css(S.BaseStyle)}>
            <Header variant="03">Something went wrong.</Header>
            <Subheader>Please refresh this page to continue.</Subheader>
            {requestId ? <p>{`Request id: ${requestId}`}</p> : null}
        </div>
    );
};

export default SomethingWrong;
