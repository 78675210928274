import { Header, Subheader, useStyles } from '@archipro-design/aria';
import * as S from './NetworkError.style';
import { useRootData } from '@modules/root/hook/use-root-data';
import React from 'react';
import { useNetwork } from 'ahooks';

const checkNetwork = (retryCount: number) => {
    if (retryCount > 5) {
        return;
    }
    fetch('/remix-api/dev/null')
        .then(() => {
            /**
             * check we can indeed connect to the server
             */
            window.location.reload();
        })
        .catch(() => {
            setTimeout(() => {
                checkNetwork(retryCount + 1);
            }, 1000);
        });
};
const NetworkError = () => {
    const { css } = useStyles();
    const { requestId } = useRootData();
    const { online } = useNetwork();
    React.useEffect(() => {
        if (online) {
            checkNetwork(0);
        }
    }, [online]);

    return (
        <div className={css(S.BaseStyle)}>
            <Header variant="03">Network is unreachable</Header>
            <Subheader>
                Having trouble contacting our servers, check your internet
                connection and try again.
            </Subheader>
            <p>{`Request id: ${requestId}`}</p>
        </div>
    );
};

export default NetworkError;
