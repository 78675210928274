import type { StyleRule } from '@archipro-design/aria';
import {
    HeaderClassName,
    pxArrayToRem,
    pxToRem,
    SubheaderClassName,
} from '@archipro-design/aria';

export const BaseStyle: StyleRule = ({ theme }) => ({
    width: '100%',
    padding: pxArrayToRem([60, 18, 80, 18]),
    color: theme.siteVariables.colorScheme.default?.foreground,

    [`& .${HeaderClassName}`]: {
        margin: pxArrayToRem([0, 0, 20, 0]),
        ...theme.siteVariables.textStyles.Text.Roman55,
        fontSize: pxToRem(32),
        color: theme.siteVariables.colorScheme.default?.foreground,
        lineHeight: theme.siteVariables.lineHeightSmall,
        letterSpacing: theme.siteVariables.letterSpacingNormal,
    },

    [`& .${SubheaderClassName}`]: {
        ...theme.siteVariables.textStyles.Text.Roman55,
        fontSize: pxToRem(18),
        lineHeight: theme.siteVariables.lineHeightDefault,
        letterSpacing: theme.siteVariables.letterSpacingNormal,
    },

    [theme.screen.min.tablet]: {
        padding: pxArrayToRem([204, 50, 240, 50]),

        [`& .${HeaderClassName}`]: {
            margin: pxArrayToRem([0, 0, 40, 0]),
            ...theme.siteVariables.textStyles.Display.Roman55,
            fontSize: pxToRem(90),
            lineHeight: theme.siteVariables.lineHeightDefault,
        },

        [`& .${SubheaderClassName}`]: {
            fontSize: pxToRem(36),
        },
    },
});
