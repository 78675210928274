import { useStyles, Container } from '@archipro-design/aria';
import * as React from 'react';
import * as S from './ErrorPageWrapper.style';

interface ErrorPageWrapperProps {
    children: React.ReactNode;
}

const ErrorPageWrapper = ({ children }: ErrorPageWrapperProps) => {
    const { css } = useStyles();

    return (
        <div className={css(S.BaseStyle)}>
            <Container>{children}</Container>
        </div>
    );
};

export default ErrorPageWrapper;
