import SomethingWrong from '@modules/error/component/something-wrong/SomethingWrong';
import ErrorPageWrapper from '@modules/error/component/error-page-wrapper/ErrorPageWrapper';
import { useLogger } from '@archipro-website/logger/client';
import { useDebug } from '@modules/error/context/DebugContext';
import { useMount } from 'ahooks';
import type { ServerError } from '@apollo/client';
import { isApolloError } from '@apollo/client';
import MatrixRain from '@modules/error/component/matrix-rain/matrix-rain';
import NetworkError from '@modules/error/component/network-error/NetworkError';

const isServerError = (error: Error | null): error is ServerError => {
    if (!error) {
        return false;
    }
    if (typeof error !== 'object') {
        return false;
    }
    return error.name === 'ServerError';
};

const isNetworkError = (error: Error | null): boolean => {
    return error?.message === 'Failed to fetch';
};

const ErrorPage = ({ error }: { error: Error }) => {
    const logger = useLogger();
    const { add } = useDebug();
    let message = error.message;
    const networkError = isNetworkError(error);
    const isDeployment = message === 'Deployment in progress';
    if (isApolloError(error)) {
        message = `Graphql Error occurred`;
        const { networkError } = error;
        if (isServerError(networkError) && networkError.statusCode === 503) {
            message = `Graphql service is unavailable`;
        }
    }
    if (typeof document === 'undefined') {
        add({ message, stack: error.stack });
    }

    useMount(() => {
        if (networkError) {
            return;
        }
        logger.crit(message, error);
        add({ message, stack: error.stack });
    });

    return (
        <ErrorPageWrapper>
            {isDeployment && <MatrixRain />}
            {!isDeployment && networkError ? (
                <NetworkError />
            ) : (
                <SomethingWrong />
            )}
        </ErrorPageWrapper>
    );
};
export default ErrorPage;
